.text-button {
    background: none;
    border: none;
    font-size: 14px;
    /* font-weight: bold; */
    cursor: pointer;
    position: relative;
    padding: 0;
    margin: 0;
    color: black;
    text-decoration: none; /* 기본 언더라인 제거 */
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.text-button::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: black;
    transform: scaleX(0);
    /* transition: transform 0.3s ease-in-out; */
}

.text-button:hover::after {
    transform: scaleX(1);
}
